import React, { useContext, useEffect, useState } from 'react';
import { LanguageContext } from './../../language/LanguageContext';
import { useParams } from 'react-router-dom';
import Footer from '../pageComponents/Footer';
import LanguageSelector from '../pageComponents/LanguageSelector';
import './Project.scss';
import CarouselComponent from '../reusableComponents/CarouselComponent';
// import { useMedia } from '../context/MediaContext'; // Import useMedia hook
import githubLogoBlack from '../../assets/img/home/github-mark.png';
import githubLogoWhite from '../../assets/img/home/github-mark-white.png';
import Navigation from '../pageComponents/Navigation';

const Project = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    const { id } = useParams();
    const { dictionary } = useContext(LanguageContext);
    // const { projectMediaLoaded } = useMedia(); // Use the useMedia hook to get projectMediaLoaded state

    const projectData = dictionary.projects.elements.find((project) => project.id === parseInt(id));
    const [githubLogo, setGithubLogo] = useState(githubLogoBlack);

    useEffect(() => {
        const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        const updateLogo = (e) => {
            setGithubLogo(e.matches ? githubLogoWhite : githubLogoBlack);
        };

        // Initial check
        updateLogo(darkModeMediaQuery);

        // Listen for changes
        darkModeMediaQuery.addEventListener('change', updateLogo);

        // Cleanup listener on component unmount
        return () => {
            darkModeMediaQuery.removeEventListener('change', updateLogo);
        };
    }, []);
    console.log(projectData.extrenalLink)

    return (
        <>
            <Navigation type="project"/>
            <LanguageSelector />
            <div className='project' style={{ backgroundImage: `url(${require(`../../assets/img/${projectData.cardImage}`)})` }}>
                <div className='overlay'>
                  
                </div>
                <div className='project-content'>
                    <CarouselComponent mediaArray={projectData.media} loading={false} />
                    {/* <CarouselComponent mediaArray={projectData.media} loading={true} /> */}

                    <div className='text-wrapper'>
                        <h1>{projectData.title}
                        {projectData.githubLink && (
                            <a href={projectData.githubLink} target="_blank" rel="noopener noreferrer">
                                <img src={githubLogo} alt="GitHub Logo" />
                            </a>
                        )}
                        </h1>
                        <p>{projectData.description}</p>
                        {projectData.bulletPoints && projectData.bulletPoints.map((section, index) => (
                            <div key={index}>
                                <h2>{section.title}</h2>
                                <ul>
                                    {section.points.map((point, idx) => (
                                        <li key={idx}>{point}</li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                          {projectData.extrenalLink && (
                            
                            <h3>
                                <a href={projectData.extrenalLink} className='fancy-link' target="_blank" rel="noopener noreferrer">
                                    {dictionary.projects.externalLinkText}
                                    {/* <img src={externalLink} alt="GitHub Logo" /> */}
                                </a>
                            </h3>
                        )}
                      
                    </div>
                </div>
            </div>
            <div className='buffer'></div>
            <Footer />
        </>
    );
};

export default Project;